<style lang="less" scoped>
.menu{
  position: relative;
  color: #fff;
  z-index: 100;
  width: 100%;
  .menu-list{
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: right;
    align-items: center;
    .menu-content{
      width: 100%;
      display: flex;
      justify-content: right;
    }
    .menu-item{
      display: inline-block;
      text-align: center;
      font-size: 18px;
      transition: background .5s;
      .menu-title{
        display: inline-block;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        position: relative;
      }
      .menu-title:hover{
        color: #FFDDA3!important;
        border-bottom: 2px solid #FFDDA3;
      }
    }

    .menu-item:hover{
      color: #FFDDA3;
    }
    .active{
      color: #FFDDA3!important;
      font-weight: bold;
      border-bottom: 2px solid #FFDDA3;
    }
    .user-icon{
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      .menu-title-signin{
        font-size: 16px;
      }
      .menu-title{
        padding: 0 15px;
        margin-left: 15px;
        line-height: 40px;
        color: #666;
        display: flex;
        transition: all .2s;
      }
      .menu-title-black-block{
        padding: 0 30px;
        color: #fff;
        border-radius: 30px;
        background: #000;
      }
    }
  }
  .menu-icon{
    position: relative;
    z-index: 11;
    margin-right: 20px;
    cursor: pointer;
    span{
      position: relative;
      bottom: 10px;
      font-weight: bold;
      margin-left: 5px;
      font-size: 26px;
    }
  }
}
@media (max-width: 768px) {
  .menu-content{
    justify-content: center!important;
  }
  .menu-icon {
    display: none;
  }
  .menu-list{
    padding-top: 0;
  }
}
</style>

<template>
  <div class="menu ">
    <div class="menu-list container">
      <div class="menu-content  d-flex flex-wrap justify-content-right justify-content-md-between py-4 mb-4">
        <div class="menu-icon" @click="goPage('/')">
           &nbsp;
        </div>
        <div class="">
       <div class="menu-item nav px-3 nav-pills" v-for="item in page" :key="item" @click="goPage(item.link)">
            <span class="menu-title nav-item" :class="{ active: currentPath === item.link }">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref } from 'vue';
import router from "@/router";

const page = [
  {
    title: '关于我们',
    link: '/',
  },
  {
    title: '产品中心',
    link: '/industry',
  },
  {
    title: '商务合作',
    link: '/contactUs',
  },
]
const currentPath = ref('');
const goPage = (link) => {
  router.push(link);
  currentPath.value = link;
  console.log(currentPath.value)
}
watchEffect(() => {
  currentPath.value = window.location.hash.replace('#', '');
  console.log(window.location.hash)
});
</script>
