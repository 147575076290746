<style lang="less" scoped>
.contanter{
  width: 100%;
  height: 735px;
  background-image: url("https://resource.jiupiao.vip/feedback/20240601/171721272495612.png");
  background-size: 100% 735px;
  background-repeat: no-repeat;
  background-position: center;
  .bodycontant{
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 256px 0;
    .left{
      text-align: center;
      margin-right: 3vw;
      img{
        display: block;
        margin: 0 auto;
      }
      .logo{
        width: 310px;
        margin-bottom: 16px;
        position: relative;
        top: 100px;
      }
      .title{
        width: 263px;
        height: 23px;
        margin-bottom: 19px;
      }
      .smtitle{
        width: 209px;
        height: 18px;
        margin-bottom: 19px;
      }
      .list{
        display: flex;
        justify-content: center;
        .icon{
          width: 43px;
          height: 43px;
          margin: 0 4px;
        }
      }
    }
    .right{
      padding-top: 75px;
      .top{
        display: flex;
        justify-content: left;
        width: 41vw;
        border-bottom: 1px solid #FFDDA3;
        padding-bottom: 14px;
        .leftBox{
          margin-right: 43px;
          width: 100%;
          .title{
            color: #FFDDA3;
            font-size: 21px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            .rightFeedBack{
              .leftIconImg{
                width: 28.31px;
                height: 28.31px;
                margin-right: 5px;
              }
              .rightIconImg{
                width: 9.73px;
                height: 16.12px;
                margin-left: 5px;
              }
            }
          }
          .list{
            color: #fff;
            font-size: 15px;
            line-height: 1.5;
            margin-left: 15px;
          }
          .list::before{
            content: '';
            width: 8px;
            height: 8px;
            background: #FFDDA3;
            border-radius: 8px;
            display: block;
            position: relative;
            top: 15px;
            right: 15px;
          }
        }
        .rightBox{
          .title{
            color: #FFDDA3;
            font-size: 21px;
            font-weight: bold;
          }
          .code{
            width: 79px;
            height: 79px;
            margin-top: 16px;
          }
        }
      }
      .btm{
        display: flex;
        justify-content: right;
        margin-top: 14px;
        .menu-item{
          margin-left: 39px;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          .menu-title{
          }
        }
        .active{
          color: #FFDDA3!important;
          font-weight: bold;
        }
      }
    }
  }
}
.modalTost{
  .showModal{
    padding: 10px 25px;
    font-size: 16px;
    background: #000;
    color: #fff;
    transition: all 1s;
    border-radius: 5px;
    opacity: 0.6;
    margin: 0 auto;
    position: fixed;
    top: calc(50vh - 41px/2);
    left: calc(50vw - 210px/2);
    z-index: 999;
    text-align: center;
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
  z-index: 999;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
  z-index: 999;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    z-index: 999;
  }
  100% {
    opacity: 1;
    z-index: 999;
  }
}
.modalTost{
  z-index: 999;
}
.bgColor{
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.modalBox{
  background-color: #fff;
  position: fixed;
  top: calc(50vh - 250px);
  left: calc(50vw - 250px);
  z-index: 11;
  padding: 50px;
  width: 500px;
  border-radius: 30px;
  .title{
    color: #191F24;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }
  .inputBox{
    .line{
      display: flex;
      .leftLabel{
        color: #1A1A1A;
        font-size: 18px;
        margin-right: 30px;
        height: 45px;
        line-height: 45px;
      }
      .rightInput{
        input{
          height: 45px;
          margin-bottom: 26px;
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid #dae7fb;
          width: 333px;
        }
        textarea{
          height: 120px;
          margin-bottom: 26px;
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid #dae7fb;
          width: 333px;
        }
      }
    }
  }
  .btmBtn{
    background: linear-gradient( 90deg, #E02917 0%, #F25640 100%);
    border-radius: 10px;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: bold;
    padding: 16px 0;
  }
}
@media (max-width: 768px) {
  .contanter{
    background-image: url("https://resource.jiupiao.vip/feedback/20240531/171715347767043.png");
    .bodycontant {
      display: block;
      padding: 138px 40px 40px;
      .left{
      }
      .right {
        margin-top: 98px;
        .top{
          border-bottom: none;
          width: auto;
          .leftBox{
            margin-right: 0;
            .title{
              font-size: 18px;
              margin-bottom: 15px;
              .rightFeedBack{
                .leftIconImg{
                  width: 20px;
                  height: 20px;
                }
                .rightIconImg{
                  width: 7px;
                  height: 12px;
                }
              }
            }
          }
        }
        .btm{
          justify-content: center;
          margin-top: 80px;
          .menu-item{
            margin: 0 10px;
          }
        }
      }
    }
  }
  .modalBox{
    top: calc(50vh - 20vh);
    left: calc(50vw - 40vw);
    width: 80%;
    padding: 3vw;
    .title{
      font-size: 4vw;
      margin-bottom: 5vw
    }
    .inputBox{
      .line{
        .leftLabel{
          font-size: 3vw;
          margin-right: 5vw;
          height: 10vw;
          line-height: 10vw;
        }
        .rightInput{
          input{
            font-size: 3vw;
            height: 10vw;
            margin-bottom: 5vw;
            padding: 2vw 4vw;
            border-radius: 2vw;
            width: 60vw;
          }
          textarea{
            font-size: 3vw;
            height: 30vw;
            margin-bottom: 5vw;
            padding: 2vw 4vw;
            border-radius: 2vw;
            width: 60vw;
          }
        }
      }
    }
    .btmBtn{
      font-size: 4vw;
      padding: 2vw 0;
      width: 60vw;
      margin: 0 auto;
    }
  }
  .modalTost {
    .showModal {
      width: 40vw;
      padding: 4vw 5vw!important;
      font-size: 3vw!important;
      border-radius: 2vw!important;
      top: calc(50vh - 11vw/2)!important;
      left: 30vw!important;
    }
  }
}
</style>

<template>
  <div class="contanter">
    <div class="bodycontant justify-content-md-center">
      <div class="left">
        <img src="https://resource.jiupiao.vip/feedback/20240624/171922599300790.png" alt="" class="logo">
      </div>
      <div class="right">
        <div class="top">
          <div class="leftBox">
            <div class="title">
              <div class="leftTitle">公司信息</div>
              <div class="rightFeedBack" @click="openModal">
                <img class="leftIconImg" src="https://resource.jiupiao.vip/feedback/20240625/171930041423294.png" alt="">
                反馈建议
                <img class="rightIconImg" src="https://resource.jiupiao.vip/feedback/20240625/17193006243842.png" alt="">
              </div>
            </div>
            <div class="list">邮&nbsp;&nbsp;&nbsp;&nbsp;箱：jm@jibeizhiyu.com</div>
            <div class="list">电&nbsp;&nbsp;&nbsp;&nbsp;话：021-51553828</div>
            <div class="list" @click="beian">备案号：沪ICP备2024072239号-1</div>
            <div class="list">地&nbsp;&nbsp;&nbsp;&nbsp;址：上海市宝山区山连路779号</div>
          </div>
        </div>
<!--        <div class="btm">-->
<!--          <div class="menu-item" v-for="item in page" :key="item" @click="goPage(item.link)">-->
<!--            <span class="menu-title" :class="{ active: currentPath === item.link }">{{ item.title }}</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="bgColor" v-if="showModalBoxStatic" @click="closeModal"></div>
    <div class="modalBox" v-if="showModalBoxStatic">
      <div class="title">意见与建议</div>
      <div class="inputBox">
        <div class="line">
          <div class="leftLabel">姓名</div>
          <div class="rightInput">
            <input v-model="form.name" type="text" placeholder="请输入您的姓名">
          </div>
        </div>
        <div class="line">
          <div class="leftLabel">电话</div>
          <div class="rightInput">
            <input v-model="form.phone" type="text" placeholder="请输入您的电话">
          </div>
        </div>
        <div class="line">
          <div class="leftLabel">留言</div>
          <div class="rightInput">
            <textarea v-model="form.memo" name="" placeholder="请输入留言"></textarea>
          </div>
        </div>
      </div>
      <div class="btmBtn" @click="submit">提交</div>
    </div>
    <Transition name="bounce">
      <div v-if="showModalStatic" class="modalTost">
        <div class="showModal">
          {{ modalText }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {merchantJoinApi} from "@/api/home";
import {ref} from "vue";

const beian = () => {
  window.open('https://beian.miit.gov.cn/', '_blank');
}
const form = ref({
  name: '',
  phone: '',
  memo: '',
});
const showModalStatic = ref(false);
const showModalBoxStatic = ref(false);
const modalText = ref();

const showModal = (e) => {
  modalText.value = e;
  showModalStatic.value = true;
  console.log(showModalStatic.value)
  setTimeout(function (){
    showModalStatic.value = false
  }, 3000)
}
const openModal = () => {
  showModalBoxStatic.value = true;
}
const closeModal = () => {
  showModalBoxStatic.value = false;
}
const submit = async () => {
  console.log('form', form.value)
  if(!form.value.name || !form.value.phone || !form.value.memo){
    showModal('请填写完整信息！');
    return
  }
  const regexPhone = /^1[3456789]\d{9}$/;
  if(!regexPhone.test(form.value.phone)){
    showModal('请填写正确的手机号！');
    return
  }
  const data = {
    username: form.value.name,
    mobile: form.value.phone,
    contactSrc: '',
    note: form.value.memo,
    address: '',
    type: 'jmkj'
  }
  await merchantJoinApi(data).then((res) => {
    form.value = {
      name: '',
      phone: '',
      email: '',
      memo: '',
    }
    if(res.data.code === 0) {
      showModal('提交成功！');
      closeModal();
    } else {
      showModal(res.data.msg);
    }
  })
}
// watchEffect(() => {
//   currentPath.value = window.location.hash.replace('#', '');
// });
</script>

